import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import {
  usePopupState,
  bindMenu,
  bindHover,
  bindTrigger,
} from 'material-ui-popup-state/hooks';
import Menu from 'material-ui-popup-state/HoverMenu';
import OptionItem from './OptionItem';

const ParentPopupState = React.createContext(null);

const SubOptions = ({ children, ...item }) => {
  const parentPopupState = React.useContext(ParentPopupState);
  const popupState = usePopupState({
    popupId: 'profile_submenu',
    variant: 'popover',
    parentPopupState,
  });

  return (
    <ParentPopupState.Provider value={popupState}>
      <OptionItem
        {...item}
        menuItemProps={{
          selected: popupState.isOpen,
          ...(!isMobile ? bindHover(popupState) : bindTrigger(popupState)),
        }}
      />
      <Menu
        css={`
          [class^='MuiPaper-root'] {
            border-top-right-radius: 0;
          }
          [class^='MuiList-root'] {
            padding: 0;
          }
        `}
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
      >
        {children.map(({ itemKey, ...itemProps }, i) => (
          <Fragment key={itemKey}>
            <OptionItem {...itemProps} />
            {i < children.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Menu>
    </ParentPopupState.Provider>
  );
};

SubOptions.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SubOptions;
