import React from 'react';
import PropTypes from 'prop-types';
import { usePopupState } from 'material-ui-popup-state/hooks';
import authStore from '@vdx-platform/core/auth/store';
import ProfileMenu from './menu';
import { PopupProvider } from './context/popup';
import Profile from './trigger';

const ProfileContainer = ({ className }) => {
  const { name, photo, loadingPhoto } = authStore.useStoreState(
    state => state.profile
  );

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'profile_menu',
  });

  return (
    <PopupProvider value={popupState}>
      <Profile
        className={className}
        name={name}
        photo={photo}
        loadingPhoto={loadingPhoto}
      />
      <ProfileMenu />
    </PopupProvider>
  );
};

ProfileContainer.propTypes = {
  className: PropTypes.string,
};

ProfileContainer.Info = Profile;
ProfileContainer.Menu = ProfileMenu;

export default ProfileContainer;
