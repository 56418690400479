import authStore from '../store';

export default (authRoles = []) => {
  const isAuthenticated = authStore.useStoreState(state => state.success);
  const userRoles = authStore.useStoreState(state => state.profile.roles);

  const isAuthorized =
    isAuthenticated &&
    (authRoles.length === 0 ||
      authRoles.some(element => userRoles.includes(element)));

  return { isAuthorized, isAuthenticated };
};
