import React, { forwardRef } from 'react';
import { IconButton } from '@material-ui/core';
import { Menu as MuiMenuIcon } from '@material-ui/icons';
import useDeviceSize from '@vdx-platform/core/sizes/breakpoints/use_screen_size';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import store from '../store';

const MenuIcon = styled(MuiMenuIcon)``;

const Button = styled(IconButton)``;

const OpenIconButton = forwardRef(({ className }, ref) => {
  const open = store.useStoreActions(actions => actions.open);
  const { isXSmall } = useDeviceSize();

  return (
    <Button
      ref={ref}
      className={className}
      onClick={open}
      color="inherit"
      aria-label="Menu"
      size={isXSmall ? 'small' : 'medium'}
    >
      <MenuIcon />
    </Button>
  );
});

OpenIconButton.propTypes = {
  className: PropTypes.string,
};

OpenIconButton.Icon = MenuIcon;
OpenIconButton.Button = Button;

export default OpenIconButton;
