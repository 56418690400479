export default async e => {
  if (e.message === 'Network Error') {
    const notFoundError = new Error('Server was unreachable or timed out');
    notFoundError.data = {
      code: 'network_error',
      description: "couldn't get a response",
    };

    throw notFoundError;
  }

  throw e;
};
