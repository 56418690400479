import React from 'react';
import styled from 'styled-components';
import { Typography, Button } from '@material-ui/core';
import PanToolIcon from '@material-ui/icons/PanTool';
import authStore from '@vdx-platform/core/auth/store';

const Root = styled.div`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 40%;
  transform: translate(-50%, -50%);
`;

const Title = styled(Typography)`
  font-size: 3rem;
  margin-top: 1rem;
  text-align: center;
`;

const Paragraph = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 1.4rem;
  margin: 5px 0;
`;

const StyledButton = styled(Button)`
  margin-top: 2rem;
`;

const Forbidden = () => {
  const logout = authStore.useStoreActions(actions => actions.logout);

  return (
    <Root>
      <PanToolIcon fontSize="large" />
      <Title variant="h1">Forbidden</Title>
      <Paragraph variant="body1" component="p">
        You do not have permission to view this page. Please login in with
        another account.
      </Paragraph>
      <StyledButton
        type="button"
        onClick={logout}
        variant="contained"
        color="primary"
        size="large"
      >
        Login
      </StyledButton>
    </Root>
  );
};

export default Forbidden;
