import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Close as MuiCloseIcon } from '@material-ui/icons';
import useDeviceSize from '@vdx-platform/core/sizes/breakpoints/use_screen_size';
import PropTypes from 'prop-types';
import store from '../store';

const CloseIcon = styled(MuiCloseIcon)``;

const Button = styled(IconButton)``;

const CloseIconButton = forwardRef(({ className }, ref) => {
  const close = store.useStoreActions(actions => actions.close);
  const { isXSmall } = useDeviceSize();

  return (
    <Button
      ref={ref}
      className={className}
      onClick={close}
      color="inherit"
      aria-label="Menu"
      size={isXSmall ? 'small' : 'medium'}
    >
      <CloseIcon />
    </Button>
  );
});

CloseIconButton.propTypes = {
  className: PropTypes.string,
};

CloseIconButton.Icon = CloseIcon;
CloseIconButton.Button = Button;

export default CloseIconButton;
