import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const useDeviceSize = () => {
  const theme = useTheme();

  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  return {
    isXSmall,
    isSmall,
    isMedium,
    isLarge,
  };
};

export default useDeviceSize;
