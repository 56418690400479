import { useQueryParams } from '@vdx/hookrouter';
import authStore from '@vdx-platform/core/auth/store';
import userTypeStore from '@vdx-platform/core/user/store';

const useQueryParamIssuer = () => {
  const [queryParams] = useQueryParams();
  const selectIssuer = queryParams.issuer;

  const currentIssuer = userTypeStore.useStoreState(state => state.issuer);
  const issuers = authStore.useStoreState(state => state.profile.issuers);
  const issuersIds = issuers.map(item => item.issuer.id);
  const setIssuer = userTypeStore.useStoreActions(actions => actions.setIssuer);

  if (selectIssuer && issuersIds.includes(selectIssuer)) {
    setIssuer(issuers.find(item => item.issuer.id === selectIssuer).issuer);
  }

  return currentIssuer;
};

export default useQueryParamIssuer;
