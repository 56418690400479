import localStorage from 'local-storage';

const ISSUER_PATHNAME = 'active_issuer';

export const remove = () => localStorage.remove(ISSUER_PATHNAME);

export const get = () => {
  const { token, expires } = localStorage.get(ISSUER_PATHNAME) || {};

  const now = new Date().getTime();

  if (expires && now >= expires) {
    remove();
    return undefined;
  }

  return token;
};

export const set = (token, expires) =>
  localStorage.set(ISSUER_PATHNAME, {
    token,
    expires,
  });

export const listen = fn => localStorage.on(ISSUER_PATHNAME, fn);

export const unlisten = fn => localStorage.off(ISSUER_PATHNAME, fn);
