import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const i18n = i18next.createInstance();

i18n
  .use(Backend)
  .use(LanguageDetector)

  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'common',
    ns: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      customHeaders: {
        Accept: 'application/json',
      },
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
