import React from 'react';
import { StylesProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CssBaseline } from '@material-ui/core';
import AppThemeProvider from './provider';

function AppTheme({ theme, children }) {
  return (
    <StylesProvider injectFirst>
      <AppThemeProvider theme={theme}>
        <>
          <CssBaseline />
          {children}
        </>
      </AppThemeProvider>
    </StylesProvider>
  );
}

AppTheme.propTypes = {
  theme: PropTypes.shape({
    palette: PropTypes.shape({}),
    brand: PropTypes.shape({}),
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default AppTheme;
