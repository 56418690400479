import { createContextStore, action } from 'easy-peasy';

const drawerSideBarInitialState = {
  isOpen: false,
};

export const drawerModel = (initialState = drawerSideBarInitialState) => ({
  ...initialState,
  toggle: action(state => {
    state.isOpen = !state.isOpen;
  }),
  close: action(state => {
    state.isOpen = false;
  }),
  open: action(state => {
    state.isOpen = true;
  }),
});

export default createContextStore(drawerModel, { name: 'Drawer' });
