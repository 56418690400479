import { readAsText } from 'promise-file-reader';

export default async error => {
  if (!error.response) throw error;

  const { responseType } = error.request;

  if (responseType !== 'blob') throw error;

  const { data, status } = error.response;

  const file = await readAsText(data);
  const errorData = JSON.parse(file);
  const apiError = new Error(`Request failed with status code ${status}`);
  apiError.data = errorData;
  apiError.status = status;

  throw apiError;
};
