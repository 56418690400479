import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import store from '../store';
import MenuCloseButton from './MenuCloseButton';
import MenuOpenButton from './MenuOpenButton';

const CloseIconButton = motion.custom(MenuCloseButton);

const OpenIconButton = motion.custom(MenuOpenButton);

const MenuIconButton = styled(({ className }) => {
  const isOpen = store.useStoreState(state => state.isOpen);

  return (
    <div className={className}>
      <AnimatePresence>
        {!isOpen && (
          <OpenIconButton
            key="open"
            initial={{ opacity: 0, zIndex: 1 }}
            animate={{ rotate: '-360deg', opacity: 1 }}
            transition={{ type: 'spring' }}
            exit={{ zIndex: 0 }}
          />
        )}
        {isOpen && (
          <CloseIconButton
            key="close"
            initial={{ opacity: 0, zIndex: 1 }}
            animate={{ rotate: '360deg', opacity: 1 }}
            transition={{ type: 'spring' }}
            exit={{ zIndex: 0 }}
          />
        )}
      </AnimatePresence>
    </div>
  );
})`
  position: relative;

  ${CloseIconButton.Button}, ${OpenIconButton.Button} {
    position: absolute;
  }

  ${CloseIconButton.Icon},
  ${OpenIconButton.Icon} {
    height: 3rem;
    width: 3rem;
  }
`;

export default MenuIconButton;
