import httpClient from '@vdx-platform/core/api/client';

export default async cancelToken => {
  const response = await httpClient.get(`/user_profile/picture`, {
    responseType: 'blob',
    cancelToken,
  });

  return response;
};
