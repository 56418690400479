import localStorage from 'local-storage';

const USER_TYPE_PATHNAME = 'user_type';

export const remove = () => localStorage.remove(USER_TYPE_PATHNAME);

export const get = () => {
  const { token, expires } = localStorage.get(USER_TYPE_PATHNAME) || {};

  const now = new Date().getTime();

  if (expires && now >= expires) {
    remove();
    return undefined;
  }

  return token;
};

export const set = (token, expires) =>
  localStorage.set(USER_TYPE_PATHNAME, {
    token,
    expires,
  });

export const listen = fn => localStorage.on(USER_TYPE_PATHNAME, fn);

export const unlisten = fn => localStorage.off(USER_TYPE_PATHNAME, fn);
