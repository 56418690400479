import { isCancel } from 'axios';

export default async e => {
  if (isCancel(e)) {
    const abortError = new Error('Request aborted');
    abortError.data = { code: 'aborted', description: 'request was aborted' };

    throw abortError;
  }

  throw e;
};
