export default error => {
  if (!error.response) return Promise.reject(error);

  const { data, status } = error.response;

  const apiError = new Error(`Request failed with status code ${status}`);
  apiError.data = data;
  apiError.status = status;

  return Promise.reject(apiError);
};
