import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, SvgIcon } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { bindTrigger } from 'material-ui-popup-state/hooks';
// import useDeviceSize from '@vdx-platform/core/sizes/breakpoints/use_screen_size';
import UserPicture from '@vdx-platform/core/shared/components/Avatar';
import defaultAvatar from '@vdx-portal/assets/default-avatar.png';
// import ResponsiveButton from '@vdx-platform/core/shared/components/ResponsiveButton';
import { usePopupState } from '../context/popup';

const ArrowVariants = {
  open: { rotate: '180deg' },
  close: { rotate: '0deg' },
};

const useStyles = makeStyles(() => ({
  picture: {
    marginRight: '1rem',
  },
}));

const TriggerArrow = forwardRef(({ isOpen, ...props }, ref) => {
  return (
    <motion.div
      initial={isOpen ? 'open' : 'close'}
      animate={isOpen ? 'close' : 'open'}
      variants={ArrowVariants}
      transition={{ type: 'spring' }}
    >
      <KeyboardArrowUp ref={ref} {...props} />
    </motion.div>
  );
});
TriggerArrow.propTypes = {
  isOpen: PropTypes.bool,
};

const Profile = styled(({ className, photo, loadingPhoto }) => {
  const popupState = usePopupState();
  const classes = useStyles();

  return (
    <IconButton
      className={className}
      {...bindTrigger(popupState)}
      color="default"
      disableRipple
    >
      <UserPicture
        src={photo || defaultAvatar}
        className={classes.picture}
        loading={loadingPhoto}
        icon={
          !photo &&
          !loadingPhoto && (
            <SvgIcon viewBox="0 0 42 42" style={{ fontSize: 50 }}>
              <path
                d="M31.4685 32.7687C30.2457 31.1497 28.6637 29.8368 26.8471 28.9334C25.0304 28.0299 23.0289 27.5606 21 27.5625C18.9711 27.5606 16.9695 28.0299 15.1529 28.9334C13.3363 29.8368 11.7543 31.1497 10.5315 32.7687M31.4685 32.7687C33.8546 30.6463 35.5374 27.8487 36.2971 24.7469C37.0567 21.6451 36.8554 18.3856 35.7201 15.4008C34.5847 12.416 32.5688 9.84683 29.9397 8.03404C27.3106 6.22125 24.1926 5.25049 20.9991 5.25049C17.8056 5.25049 14.6876 6.22125 12.0585 8.03404C9.42947 9.84683 7.41357 12.416 6.27818 15.4008C5.14279 18.3856 4.94156 21.6451 5.70118 24.7469C6.46079 27.8487 8.14537 30.6463 10.5315 32.7687M31.4685 32.7687C28.588 35.338 24.8598 36.7555 21 36.75C17.1395 36.7559 13.4124 35.3384 10.5315 32.7687M26.25 17.0625C26.25 18.4549 25.6969 19.7902 24.7123 20.7748C23.7277 21.7593 22.3924 22.3125 21 22.3125C19.6076 22.3125 18.2722 21.7593 17.2877 20.7748C16.3031 19.7902 15.75 18.4549 15.75 17.0625C15.75 15.6701 16.3031 14.3347 17.2877 13.3502C18.2722 12.3656 19.6076 11.8125 21 11.8125C22.3924 11.8125 23.7277 12.3656 24.7123 13.3502C25.6969 14.3347 26.25 15.6701 26.25 17.0625Z"
                stroke="#454545"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </SvgIcon>
          )
        }
        // icon={
        //   <SvgIcon viewBox="0 0 42 42" style={{ fontSize: 50 }}>
        //     <path
        //       d="M31.4685 32.7687C30.2457 31.1497 28.6637 29.8368 26.8471 28.9334C25.0304 28.0299 23.0289 27.5606 21 27.5625C18.9711 27.5606 16.9695 28.0299 15.1529 28.9334C13.3363 29.8368 11.7543 31.1497 10.5315 32.7687M31.4685 32.7687C33.8546 30.6463 35.5374 27.8487 36.2971 24.7469C37.0567 21.6451 36.8554 18.3856 35.7201 15.4008C34.5847 12.416 32.5688 9.84683 29.9397 8.03404C27.3106 6.22125 24.1926 5.25049 20.9991 5.25049C17.8056 5.25049 14.6876 6.22125 12.0585 8.03404C9.42947 9.84683 7.41357 12.416 6.27818 15.4008C5.14279 18.3856 4.94156 21.6451 5.70118 24.7469C6.46079 27.8487 8.14537 30.6463 10.5315 32.7687M31.4685 32.7687C28.588 35.338 24.8598 36.7555 21 36.75C17.1395 36.7559 13.4124 35.3384 10.5315 32.7687M26.25 17.0625C26.25 18.4549 25.6969 19.7902 24.7123 20.7748C23.7277 21.7593 22.3924 22.3125 21 22.3125C19.6076 22.3125 18.2722 21.7593 17.2877 20.7748C16.3031 19.7902 15.75 18.4549 15.75 17.0625C15.75 15.6701 16.3031 14.3347 17.2877 13.3502C18.2722 12.3656 19.6076 11.8125 21 11.8125C22.3924 11.8125 23.7277 12.3656 24.7123 13.3502C25.6969 14.3347 26.25 15.6701 26.25 17.0625Z"
        //       stroke="#454545"
        //       strokeWidth="1.5"
        //       strokeLinecap="round"
        //       strokeLinejoin="round"
        //     />
        //   </SvgIcon>
        // }
      />
    </IconButton>
  );
})`
  ${p => p.theme.mixins.toolbar}

  padding-left: 1rem;
  padding-right: 1rem;
`;

export default Profile;
