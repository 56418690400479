import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@material-ui/core/styles';

function AppHelmet() {
  const { brand } = useTheme();

  return (
    <Helmet>
      <link id="favicon" rel="icon" type="image/x-icon" href={brand.favicon} />
      <title>{brand.name}</title>
    </Helmet>
  );
}

export default AppHelmet;
