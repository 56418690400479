import React, { useMemo } from 'react';
import muiThemeTransformer from '@vdx-platform/core/vizidox-brand-helpers/theme/mui-theme-transformer';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import SnackbarProvider from '../snackbar';

function AppThemeProvider({ theme, children }) {
  const appTheme = useMemo(() => muiThemeTransformer(theme), [theme]);

  return (
    <MuiThemeProvider theme={appTheme}>
      <ThemeProvider theme={appTheme}>
        <SnackbarProvider>{children}</SnackbarProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

AppThemeProvider.propTypes = {
  theme: PropTypes.shape({
    palette: PropTypes.shape({}),
    brand: PropTypes.shape({}),
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default AppThemeProvider;
