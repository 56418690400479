import React from 'react';
import styled from 'styled-components';
import { Typography, Button } from '@material-ui/core';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

const Root = styled.div`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 40%;
  transform: translate(-50%, -50%);
`;

const Title = styled(Typography)`
  font-size: 3rem;
  margin-top: 1rem;
  text-align: center;
`;

const Paragraph = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 1.4rem;
  margin: 5px 0;
`;

const StyledButton = styled(Button)`
  margin-top: 2rem;
`;

const InternalServerError = () => (
  <Root>
    <SentimentVeryDissatisfiedIcon fontSize="large" />
    <Title variant="h1">Internal Server Error</Title>
    <Paragraph variant="body1" component="p">
      Something went wrong with the server.
    </Paragraph>
    <StyledButton
      type="button"
      variant="contained"
      color="primary"
      size="large"
      href="/"
    >
      Go to Homepage
    </StyledButton>
  </Root>
);

export default InternalServerError;
