import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { makeStyles, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  base: {
    fontSize: 'inherit',
  },
}));

export default function Snackbar({ children }) {
  const classes = useStyles();

  const notistackRef = useRef();
  const onClickDismiss = useCallback(
    key => () => {
      notistackRef.current.closeSnackbar(key);
    },
    []
  );

  const actionButton = useCallback(
    key => (
      <IconButton color="inherit" onClick={onClickDismiss(key)} size="small">
        <Close />
      </IconButton>
    ),
    [onClickDismiss]
  );

  return (
    <SnackbarProvider
      ref={notistackRef}
      action={actionButton}
      maxSnack={3}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
        base: classes.base,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

Snackbar.propTypes = {
  children: PropTypes.node,
};
