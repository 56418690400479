import { actionOn, thunk, action } from 'easy-peasy';
import capitalize from 'lodash/capitalize';

export default (actionName, thunkAction) => {
  const getName = `get${capitalize(actionName)}`;

  return {
    [getName]: thunk(thunkAction),
    [`onGet${capitalize(actionName)}Success`]: actionOn(
      actions => actions[getName].successType,
      (state, { result }) => {
        state[actionName] = result;
        state.success = true;
        state.loading = false;
        state.error = undefined;
      }
    ),
    [`onGet${capitalize(actionName)}Start`]: actionOn(
      actions => actions[getName].startType,
      state => {
        state.loading = true;
      }
    ),
    [`onGet${capitalize(actionName)}Error`]: actionOn(
      actions => actions[getName].failType,
      (state, { error }) => {
        state.success = false;
        state.loading = false;
        state.error = error.data;
      }
    ),
    [`set${capitalize(actionName)}`]: action((state, payload) => {
      state[actionName] = payload;
      state.loading = false;
      state.success = true;
      state.error = undefined;
    }),
  };
};
