export default (handler, statusCodes) => error => {
  const status = error.response?.status;

  const reject = Promise.reject.bind(Promise);

  const errorHandlerMap = {
    function: () => (statusCodes(status) && handler) || reject,
    object: () => (statusCodes.find(s => s === status) && handler) || reject,
    undefined: () => handler,
  };

  const errorHandler = errorHandlerMap[typeof statusCodes]();
  return errorHandler(error);
};
