/* eslint-disable babel/camelcase */

import issuersService from './get_issuers';
import httpClient from '../../api/client';

export default async () => {
  const profileResponse = await httpClient.get('/user_profile');
  const issuersList = await issuersService();

  const { uid, email, name, photo_id, roles, public_picture } = profileResponse;

  return {
    uid,
    name,
    email,
    photo_id,
    roles,
    issuers: issuersList,
    public_picture,
  };
};
