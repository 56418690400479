import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'window-location-origin';

import React from 'react';
import { render } from 'react-dom';
import { setBasepath } from '@vdx/hookrouter';
import { getRelativeUri } from '@vdx-platform/core/shared/utils/url/parse_utils';
import App from './App';

setBasepath(getRelativeUri(process.env.PUBLIC_URL));

render(<App />, document.getElementById('root'));
