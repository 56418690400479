import React from 'react';
import { Typography, MenuItem, ListItemIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { A } from '@vdx/hookrouter';
import styled from 'styled-components';

const MenuItemButton = styled.div`
  color: inherit;
  display: flex;
  flex-grow: 1;
  min-height: 48px;
  min-width: 90px;
  padding: 0.2rem 1rem;
  text-decoration: none;
`;

const buttonContentStyles = `
display: flex;
width: 100%;

& > * {
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
`;

const OptionItem = styled(
  ({
    className,
    onClick,
    label,
    icon,
    Anchor = A,
    AnchorProps = {},
    menuItemProps = {},
  }) => {
    return (
      <MenuItem className={className} onClick={onClick} {...menuItemProps}>
        <MenuItemButton as={Anchor} {...AnchorProps}>
          <span css={buttonContentStyles}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <Typography variant="body1" noWrap>
              {label}
            </Typography>
          </span>
        </MenuItemButton>
      </MenuItem>
    );
  }
)`
  color: ${({ theme }) => theme.palette.default.main};
  padding: 0;
`;

OptionItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf({}),
};

export default OptionItem;
